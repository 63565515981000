import {
  cancel,
  delay,
  fork,
  put,
  race,
  select,
  take,
  takeEvery,
  takeLatest
} from 'redux-saga/effects'
import {
  MODERATE_START,
  MODERATE_STOP,
  MODERATE_GET_SUCCESS,
  MODERATE_HANDLE_REQUEST,
  MODERATE_HANDLE_SUCCESS,
  MODERATE_START_STATE_POLLING,
  MODERATE_STOP_STATE_POLLING,
  MODERATE_BAN_USER,
  MODERATE_HANDLE_BATCH_REQUEST,
  MODERATE_RESET_MODERATION,
  MODERATE_STATE_REQUEST
} from '../actions/action-types'
import {
  moderateGetSuccessAction,
  moderateHandleBatchSuccessAction,
  moderateHandleSuccessAction,
  moderateStateSuccessAction,
  moderateErrorAction,
  moderateResetModerationSuccessAction
} from '../actions/moderate-actions'
import {
  claimComments,
  getComments,
  handleComment,
  handleComments,
  getModerationState,
  banAuthorOfComment,
  resetModeration
} from '../api/backend-api'
import { commentsSelector } from '../selectors/moderate-selector'
import { configSelector } from '../selectors/config-selector'
import { starredLabel } from '../constants/comment-labels'
import {
  enqueueErrorSnackbarAction,
  enqueueSuccessSnackbarAction
} from '../actions/snackbar-actions'

export function* watchModerateStart() {
  yield takeLatest(MODERATE_START, moderateStartSaga)
}

function* moderateStartSaga({ chatId }) {
  try {
    const config = yield select(configSelector)
    const comments = yield getComments(config, chatId)
    if (comments.length === 0) {
      yield* claimCommentsSaga({ chatId })
    } else {
      yield put(moderateGetSuccessAction({ comments }))
    }
  } catch (error) {
    yield put(moderateErrorAction({ error }))
  }
}

function* claimCommentsSaga({ chatId }) {
  const { retryDelay, ...config } = yield select(configSelector)
  while (true) {
    try {
      const comments = yield claimComments(config, chatId)
      if (comments.length) {
        yield put(moderateGetSuccessAction({ comments }))
        return
      }
      const { stop } = yield race({
        stop: take([MODERATE_STOP, MODERATE_GET_SUCCESS]),
        timeout: delay(retryDelay)
      })
      if (stop) {
        return
      }
    } catch (error) {
      yield put(moderateErrorAction({ error }))
    }
  }
}

export function* watchModerateStop() {
  yield takeLatest(MODERATE_STOP, moderateStopSaga)
}

function* moderateStopSaga({ chatId }) {
  try {
    const config = yield select(configSelector)
    const comments = yield select(commentsSelector)
    yield handleComments(config, chatId, comments, 'return')
    yield put(moderateHandleBatchSuccessAction())
  } catch (error) {
    yield put(moderateErrorAction({ error }))
  }
}

export function* watchHandleComment() {
  yield takeEvery(MODERATE_HANDLE_REQUEST, handleCommentSaga)
}

function* handleCommentSaga({ chatId, commentId, action, isStarred }) {
  try {
    const config = yield select(configSelector)
    const body = isStarred ? { labels: [starredLabel] } : null
    yield handleComment(config, chatId, commentId, action, body)
    yield put(moderateHandleSuccessAction({ commentId, chatId }))
  } catch (error) {
    yield put(moderateErrorAction({ error }))
  }
}

export function* watchHandleCommentSuccess() {
  yield takeEvery(MODERATE_HANDLE_SUCCESS, handleCommentSuccessSaga)
}

function* handleCommentSuccessSaga({ chatId }) {
  const comments = yield select(commentsSelector)
  if (comments.length === 0) {
    yield* claimCommentsSaga({ chatId })
  }
}

export function* watchHandleCommentBatch() {
  yield takeEvery(MODERATE_HANDLE_BATCH_REQUEST, handleCommentBatchSaga)
}

function* handleCommentBatchSaga({ chatId, action }) {
  try {
    const config = yield select(configSelector)
    const comments = yield select(commentsSelector)
    yield handleComments(config, chatId, comments, action)
    yield put(moderateHandleBatchSuccessAction())
    yield* claimCommentsSaga({ chatId })
  } catch (error) {
    yield put(moderateErrorAction({ error }))
  }
}

export function* watchModerationStateRequestSaga() {
  yield takeLatest(MODERATE_STATE_REQUEST, updateModerationStateOnceSaga)
}

function* updateModerationStateOnceSaga({ chatId }) {
  const config = yield select(configSelector)
  try {
    const data = yield getModerationState(config, chatId)
    yield put(moderateStateSuccessAction({ data }))
  } catch (error) {
    yield put(moderateErrorAction({ error }))
  }
}

export function* watchPollModerationState() {
  yield takeLatest(MODERATE_START_STATE_POLLING, pollModerationState)
}

function* updateModerationStateSaga({ chatId }) {
  const { retryDelay, ...config } = yield select(configSelector)
  try {
    while (true) {
      const data = yield getModerationState(config, chatId)
      yield put(moderateStateSuccessAction({ data }))
      yield delay(retryDelay)
    }
  } catch (error) {
    yield put(moderateErrorAction({ error }))
  }
}

function* pollModerationState({ chatId }) {
  const updateStateTask = yield fork(updateModerationStateSaga, { chatId })
  yield take(MODERATE_STOP_STATE_POLLING)
  yield cancel(updateStateTask)
}

export function* watchBanUser() {
  yield takeEvery(MODERATE_BAN_USER, handleBanUser)
}

function* handleBanUser({ commentId, chatId, reason }) {
  try {
    const config = yield select(configSelector)
    yield banAuthorOfComment(config, { commentId, reason })
    yield put(moderateHandleSuccessAction({ commentId, chatId }))
  } catch (error) {
    yield put(moderateErrorAction({ error }))
  }
}

export function* watchResetModeration() {
  yield takeEvery(MODERATE_RESET_MODERATION, handleResetModeration)
}

function* handleResetModeration({ chatId }) {
  try {
    const config = yield select(configSelector)
    const moderationState = yield resetModeration(config, chatId)
    yield put(moderateResetModerationSuccessAction({ moderationState }))
    yield put(enqueueSuccessSnackbarAction({ message: 'Comments have been returned to queue' }))
  } catch (error) {
    yield put(enqueueErrorSnackbarAction({ error }))
  }
}
