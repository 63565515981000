import {
  MODERATE_START,
  MODERATE_STOP,
  MODERATE_GET_SUCCESS,
  MODERATE_HANDLE_REQUEST,
  MODERATE_HANDLE_SUCCESS,
  MODERATE_HANDLE_BATCH_REQUEST,
  MODERATE_HANDLE_BATCH_SUCCESS,
  MODERATE_START_STATE_POLLING,
  MODERATE_STOP_STATE_POLLING,
  MODERATE_STATE_SUCCESS,
  MODERATE_BAN_USER,
  MODERATE_ERROR,
  MODERATE_RESET_MODERATION,
  MODERATE_RESET_MODERATION_SUCCESS,
  MODERATE_RESET_MODERATION_ERROR,
  MODERATE_STATE_REQUEST
} from './action-types'

export function moderateStartAction({ chatId }) {
  return {
    type: MODERATE_START,
    chatId
  }
}

export function moderateStopAction({ chatId }) {
  return {
    type: MODERATE_STOP,
    chatId
  }
}

export function moderateGetSuccessAction({ comments }) {
  return {
    type: MODERATE_GET_SUCCESS,
    comments
  }
}

export function moderateAcceptCommentAction({ chatId, commentId, isStarred }) {
  return {
    type: MODERATE_HANDLE_REQUEST,
    action: 'accept',
    chatId,
    commentId,
    isStarred
  }
}

export function moderateRejectCommentAction({ chatId, commentId }) {
  return {
    type: MODERATE_HANDLE_REQUEST,
    action: 'reject',
    chatId,
    commentId
  }
}

export function moderateHandleBatchAction({ chatId, action }) {
  return {
    type: MODERATE_HANDLE_BATCH_REQUEST,
    action,
    chatId
  }
}

export function moderateHandleBatchSuccessAction() {
  return {
    type: MODERATE_HANDLE_BATCH_SUCCESS
  }
}

export function moderateHandleSuccessAction({ chatId, commentId }) {
  return {
    type: MODERATE_HANDLE_SUCCESS,
    chatId,
    commentId
  }
}

export function moderateStartStatePollingAction({ chatId }) {
  return {
    type: MODERATE_START_STATE_POLLING,
    chatId
  }
}

export function moderateStopStatePollingAction() {
  return {
    type: MODERATE_STOP_STATE_POLLING
  }
}

export function moderateStateRequestAction({ chatId }) {
  return {
    type: MODERATE_STATE_REQUEST,
    chatId
  }
}

export function moderateStateSuccessAction({ data }) {
  return {
    type: MODERATE_STATE_SUCCESS,
    data
  }
}

export function moderateBanUserAction({ commentId, chatId, reason }) {
  return {
    type: MODERATE_BAN_USER,
    commentId,
    chatId,
    reason
  }
}

export function moderateErrorAction({ error }) {
  return {
    type: MODERATE_ERROR,
    error
  }
}

export function moderateResetModerationAction({ chatId }) {
  return {
    type: MODERATE_RESET_MODERATION,
    chatId
  }
}

export function moderateResetModerationSuccessAction({ moderationState }) {
  return {
    type: MODERATE_RESET_MODERATION_SUCCESS,
    moderationState
  }
}

export function moderateResetModerationErrorAction() {
  return {
    type: MODERATE_RESET_MODERATION_ERROR
  }
}
