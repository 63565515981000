/* istanbul ignore file */

import { all, fork } from 'redux-saga/effects'
import { watchGetChat, watchGetChats, watchGetChatStats } from './chats-saga'
import { watchNotifyError } from './error-notifier-saga'
import { watchUpdateChat, watchArchiveChat } from './chat-update-saga'
import { watchInitializeUser, watchUserLogout } from './user-saga'
import {
  watchModerateStart,
  watchModerateStop,
  watchHandleComment,
  watchHandleCommentSuccess,
  watchHandleCommentBatch,
  watchPollModerationState,
  watchBanUser,
  watchResetModeration,
  watchModerationStateRequestSaga
} from './moderate-saga'
import { watchGetPolls } from './polls-saga'
import { watchUpdatePoll } from './poll-update-saga'
import { watchUpdatePollStatus } from './poll-status-saga'
import { watchGetPollResults, watchPublishPollResults } from './poll-results-saga'
import { watchGetModeratedComments, watchPostModerateCommentSaga } from './comments-saga'
import { watchGetPollStats } from './poll-stats-saga'
import { watchGetBannedUsers, watchUnbanUser } from './banned-users-saga'
import { watchGetUsers, watchAddUser, watchRemoveUser, watchEditUser } from './user-list-saga'
import { watchGetEmojis } from './emoji-saga'

export function* rootSaga() {
  yield all([
    fork(watchGetChats),
    fork(watchGetChat),
    fork(watchGetChatStats),
    fork(watchNotifyError),
    fork(watchInitializeUser),
    fork(watchUserLogout),
    fork(watchModerateStart),
    fork(watchModerateStop),
    fork(watchHandleComment),
    fork(watchHandleCommentSuccess),
    fork(watchHandleCommentBatch),
    fork(watchBanUser),
    fork(watchModerationStateRequestSaga),
    fork(watchResetModeration),
    fork(watchPollModerationState),
    fork(watchGetPolls),
    fork(watchGetPollResults),
    fork(watchPublishPollResults),
    fork(watchGetPollStats),
    fork(watchUpdatePoll),
    fork(watchUpdatePollStatus),
    fork(watchUpdateChat),
    fork(watchArchiveChat),
    fork(watchGetModeratedComments),
    fork(watchPostModerateCommentSaga),
    fork(watchGetBannedUsers),
    fork(watchUnbanUser),
    fork(watchGetUsers),
    fork(watchAddUser),
    fork(watchRemoveUser),
    fork(watchEditUser),
    fork(watchGetEmojis)
  ])
}
