import React from 'react'
import { Paper, Typography } from '@mui/material'
import { styled } from '@mui/system'
import { moderationStateProps } from '../prop-types'

const Status = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2)
}))

const Row = styled('div')({
  display: 'flex',
  flexDirection: 'row'
})

const Column = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(1),
  paddingRight: theme.spacing(2)
}))

export function ModerationStatus({ moderationStatus }) {
  return (
    <Status>
      <Typography variant="caption">Messages</Typography>
      <Row>
        <Column>
          <Typography variant="body2">{`in moderation: ${moderationStatus.moderation}`}</Typography>
          <Typography variant="body2">{`in queue: ${moderationStatus.queued}`}</Typography>
        </Column>
        <Column>
          <Typography variant="body2">{`accepted: ${moderationStatus.accepted}`}</Typography>
          <Typography variant="body2">{`rejected: ${moderationStatus.rejected}`}</Typography>
        </Column>
      </Row>
    </Status>
  )
}

ModerationStatus.propTypes = {
  moderationStatus: moderationStateProps.isRequired
}
