/* istanbul ignore file */

import { arrayOf, bool, exact, number, oneOf, oneOfType, shape, string, func } from 'prop-types'
import { pollTypes } from '../constants/poll-settings-options'

export const listUserProps = shape({
  nick: string,
  externalId: string,
  externalIdSource: string,
  id: string
})

export const moderatorProps = shape({
  nick: string,
  externalId: string,
  externalIdSource: string,
  id: string
})

export const userProps = exact({
  loggedIn: bool.isRequired,
  id: string,
  firstName: string,
  lastName: string,
  email: string,
  photoUrl: string,
  isModerator: bool,
  isOperator: bool,
  isSuperOperator: bool
})

export const writerProps = shape({
  id: string,
  nick: string
})

export const imageProps = shape({
  id: string,
  version: string,
  alt: arrayOf(
    shape({
      language: string,
      value: string
    })
  ),
  blurhash: string,
  crop: shape({
    x: number,
    y: number,
    width: number,
    height: number
  })
})

export const chatProps = shape({
  writersFromDB: arrayOf(writerProps),
  startTime: string,
  endTime: string,
  title: string,
  description: string,
  isLocked: bool,
  id: string,
  path: string,
  programId: string,
  areenaProgramIds: arrayOf(string),
  isLivefeed: bool,
  settings: shape({
    theme: string,
    bestView: bool,
    feedReactions: arrayOf(string)
  }),
  ogTitle: string,
  ogDescription: string,
  image: imageProps,
  ogImageUrl: string,
  lander: string,
  createdBy: string
})

export const commentProps = shape({
  author: string,
  content: string,
  id: string,
  topicId: string,
  acceptedAt: string,
  moderatedBy: string
})

export const pollResultProps = shape({
  option: string,
  count: number,
  percentage: string
})

export const sliderPollResultProps = shape({
  average: number,
  count: number,
  results: shape({})
})

export const pollProps = shape({
  title: string,
  pollType: oneOf(pollTypes),
  // contains either options or sliderOptions
  sliderOptions: shape({
    leftText: string,
    rightText: string,
    emoji: string
  }),
  options: arrayOf(string),
  isOpened: bool,
  isClosed: bool,
  id: number,
  results: oneOfType([arrayOf(pollResultProps), sliderPollResultProps])
})

export const moderationStateProps = shape({
  accepted: number,
  rejected: number,
  queued: number,
  moderation: number
})

export const chatStatsProps = shape({
  topicId: string,
  externalId: string,
  commentsTotal: number,
  commentsAcceptedTotal: number,
  commentsRejectedTotal: number,
  commentsQueued: number,
  commentersTotal: number,
  mostActiveCommenters: arrayOf(
    shape({
      nick: string,
      commentsCount: number
    })
  ),
  mostLikedComments: arrayOf(
    shape({
      nick: string,
      comment: string,
      likesCount: number
    })
  ),
  reactions: arrayOf(
    shape({
      reactionType: string,
      reactionCount: number,
      name: string,
      imsPath: string
    })
  )
})

export const bannedUserProps = shape({
  nick: string,
  isActive: bool,
  isBanned: bool
})

export const tableActionsProps = shape({
  onPrev: func.isRequired,
  onNext: func.isRequired,
  isPrevDisabled: bool.isRequired,
  isNextDisabled: bool.isRequired
})

export const tableColumnsProps = arrayOf(
  shape({
    header: func,
    cell: func
  })
)

export const emojiProps = shape({
  id: string.isRequired,
  imsId: string,
  imsPath: string.isRequired,
  name: string.isRequired
})
